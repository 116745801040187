/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.offscreen-nav');
var $mainNavLinks = $('.offscreen-nav .has-dropdown');
var $navToggle = $('.nav-toggle');
var $body = $('body');
var navState = 'closed';

$navToggle.click(function(e) {
    e.preventDefault();

    if(navState === 'closed') {
      openNav();
    } else {
      closeNav();
    }
});

$mainNavLinks.click(function() {
  var $clickedMainNavLink = $(this);
  $clickedMainNavLink.find('.dropdown').slideToggle();
  $clickedMainNavLink.toggleClass('open');
});

function closeNav() {
  $navToggle.removeClass('active');
  $body.removeClass('nav-overlay');
  $mainNav.removeClass('open');
  $navToggle.children('.hamburger').removeClass('active');
  navState = 'closed';
}

function openNav() {
  $navToggle.addClass('active');
  $body.addClass('nav-overlay');
  $mainNav.addClass('open');
  $navToggle.children('.hamburger').addClass('active');
  navState = 'open';
}

function toggleSlide() {

}


/* =======================
Newsletter subscription
========================*/
if($('.newsletter-subscribe-form').length) {
  $('.newsletter-subscribe-form').on('submit', function(e) {
    e.preventDefault();
    subscribeToNewsletter(this.email.value, this.dataset.redirect, this.email, this.dataset.location);
  });
}

function subscribeToNewsletter(emailAddress, thanksUrl, errorAppendField, location, gardeningCalendar) {
  let emailAttributes = {
    email: emailAddress,
    location: location
  };
  if(gardeningCalendar) {
    emailAttributes.gardeningCalendar = gardeningCalendar;
  }
  $.ajax({
    type: 'POST',
    url: 'https://summerwinds-email-app.herokuapp.com/email-subscribe',
    data: emailAttributes,
    success: function (data) {
      window.location = thanksUrl;
    },
    error: function (err) {
      if(err.status == 500) {
        $('<p class="newsletter-subscribe-error">Invalid email address entered</p>').insertAfter(errorAppendField);
      }
      console.log (err);
    }
  });
}

/* =======================
Sliders
========================*/
$(document).ready(function(){
  $('.hero-slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true
  });
});


/* =======================
Accordions
========================*/
$(".accordion").accordionjs({
  activeIndex: false,
  closable: true,
  slideSpeed: 350
});


/* =======================
Search
========================*/
(() => {

  let siteSearchContainer = document.querySelector('.site-search');

  if(siteSearchContainer) {

    const SEARCHRESULTS_TEMPLATE = `
      <div class="addsearch-searchresults">
        {{#if resultcount}}
          {{> numberOfResultsTemplate }}
        {{/if}}
        {{#each hits}}
          <a href="{{url}}" data-analytics-click="{{id}}" class="hit{{#equals type "PROMOTED"}} promoted{{/equals}}">
              <h3>{{#if title}} {{{title}}} {{else}} {{removeTrailingQueriesFromUrl url}} {{/if}}</h3>
              <div class="highlight">
                {{> searchResultImageTemplate}}
                {{{highlight}}}{{#not type "PROMOTED"}}&#8230;{{/not}}
              </div>
              {{#gt categories.length 1}}
                <div class="category">
                  <span class="category-heading">Found in:</span>{{selectCategory ..}}
                </div>
              {{/gt}}
          </a>
        {{/each}}
      </div>
    `;

    // Modal search
    let client = new AddSearchClient('c3ea8d79460707ac84b949aceac296e2');
    client.setThrottleTime(700);
    let config = {
      baseFilters: {
        category: ''
      },
      analyticsCallback: sendSearchToAnalytics
    };

    function sendSearchToAnalytics(e) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'view_search_results',
        'num_results': e.numberOfResults,
        'search_or_click': e.action,
        'search_term': e.keyword
      });
    }

    let siteSection = window.location.pathname.split('/')[1];
    if(siteSection === 'az') {
      config.baseFilters.category = "1xaz";
    }

    if(siteSection === 'ca') {
      config.baseFilters.category = "1xca";
    }

    window.searchui = new AddSearchUI(client, config);

    searchui.searchField({
      selectorToBind: '#site-search-input',
      searchAsYouType: true
    });

    searchui.searchResults({
      containerId: 'site-search-autocomplete',
      template: SEARCHRESULTS_TEMPLATE
    });

    searchui.loadMore({
      containerId: 'site-search-load-more',
      type: AddSearchUI.LOAD_MORE_TYPE.BUTTON
    });

    searchui.start();
  }
})();


/* =======================
Event location filtering
========================*/
document.addEventListener('alpine:init', () => {
  Alpine.data('eventFiltering', () => ({
    displayAll: true,
    locations: [],
    showAllLocations() {
      this.displayAll = true;
      this.locations = [];
    },
    toggleLocation(location) {
      this.displayAll = false;
      if(this.locations.length === 0) {
        this.displayAll = true;
      }
    },
    showLocation(location) {
      if(this.displayAll || this.locations.find(item => item === location)) {
        return true
      }
    }
  }))

  Alpine.store('siteSearch', {
    init() {
      let params = new URLSearchParams(window.location.search);
      let searchParam = params.get('search');
      if(searchParam) {
        this.openSearch();
        this.searchTerm = searchParam;
      }
      this.slider = document.querySelector('.hero-slider');
    },

    open: false,
    visible: false,
    currentScroll: 0,
    searchTerm: '',
    slider: null,

    clearSearch() {
      this.resetSearch();
    },

    closeSearch() {
      this.open = false;
      this.visible = false;
      this.resetSearch();
      document.body.classList.remove('offscreen-nav-visible');
      if(this.slider) {
        $('.hero-slider').slick('slickPlay');
      }
    },

    openSearch() {
      this.open = true;
      this.visible = false;
      document.body.classList.add('offscreen-nav-visible');
      if(this.slider) {
        $('.hero-slider').slick('slickPause');
      }
    },

    resetSearch() {
      this.currentScroll = window.scrollY;
      this.searchTerm = '';
      searchui.clear();
    }
  });


  Alpine.data('siteSearch', () => ({
    init() {

      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "attributes") {
            if(!mutation.target.style.display) {
              this.$nextTick(() => {
                if(!Alpine.store('siteSearch').visible) {
                  this.$focus.focus(document.querySelector('#site-search-input'))
                  this.open = true;
                  Alpine.store('siteSearch').visible = true;
                }
              })
            }
          }
        }
      };
      const config = { attributes: true, childList: false, subtree: false };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(document.querySelector('.site-search-wrapper'), config);
    },
    open: false,

    openSearch() {
      Alpine.store('siteSearch').openSearch();
      // this.$focus.focus(document.querySelector('#site-search-input'))
      // this.open = true;
    }
  }));

  Alpine.data('soilCalculator', () => ({
    init() {
      this.$watch('containerShape', value => this.setSquareWidth());
      this.$watch('dimensions', value => {
        this.setSquareWidth(value);
        this.validateInchesAmounts(value);
      });
    },
    bagSize: 1.5,
    bagsNeeded: 0,
    containerType: 'bed',
    containerShape: 'rectangle',
    dimensions: {
      length: {
        feetAmount: 0,
        inchesAmount: 0,
        totalInches: 0
      },
      width: {
        feetAmount: 0,
        inchesAmount: 0,
        totalInches: 0
      },
      depth: {
        feetAmount: 0,
        inchesAmount: 0,
        totalInches: 0
      },
      diameter: {
        feetAmount: 0,
        inchesAmount: 0,
        radiusTotalInches: 0
      }
    },
    resultsVisible: false,
    results: {
      containerType: '',
      containerShape: '',
      diameter: '',
      totalDepth: '',
      totalSquareFootage: '',
      visible: false
    },
    soilNeeded:{
      cubicFeetAmount: 0,
      cubicYardsAmount: 0,
      measurementUnit: 'cubic feet'
    },
    totalDepth: '',
    totalSquareFootage: 0,

    calculateBagsNeeded() {
      this.bagsNeeded = Math.ceil(this.soilNeeded.cubicFeetAmount / this.bagSize);
    },

    calculateSoilAmount() {
      let length = this.convertToInches(this.dimensions.length.feetAmount) + this.dimensions.length.inchesAmount;
      let width = this.convertToInches(this.dimensions.width.feetAmount) + this.dimensions.width.inchesAmount;
      let depth = this.convertToInches(this.dimensions.depth.feetAmount) + this.dimensions.depth.inchesAmount;
      let radius = (this.convertToInches(this.dimensions.diameter.feetAmount) + this.dimensions.diameter.inchesAmount)  / 2;

      this.dimensions.length.totalInches = length;
      this.dimensions.width.totalInches = width;
      this.dimensions.depth.totalInches = depth;
      this.dimensions.diameter.radiusTotalInches = radius;

      // Convert to inches, divide by 1728 (12*12*12) to get a cubic foot, then divide by 27 (3*3*3) to convert to cubic yards
      let soilNeededFeet = ((length * width * depth) / 1728);

      if(this.containerShape === 'circle') {
        soilNeededFeet = (Math.PI * (radius * radius) * depth) / 1728;
      }

      this.soilNeeded.cubicFeetAmount = Number(soilNeededFeet).toFixed(2);
      this.soilNeeded.cubicYardsAmount = Number(soilNeededFeet / 27).toFixed(2);
      this.results.containerShape = this.containerShape;
      this.results.containerType = this.containerType;
      this.results.diameter = `${this.dimensions.diameter.feetAmount ? this.dimensions.diameter.feetAmount + "'": ''}${this.dimensions.diameter.inchesAmount ? this.dimensions.diameter.inchesAmount + '"': ''}`;
      this.calculateBagsNeeded();
      this.calculateSquareFootage();
      this.calculateTotalDepth();
      this.results.visible = true;
    },

    calculateSquareFootage() {
     this.results.totalSquareFootage = Math.floor((this.dimensions.length.totalInches / 12) * (this.dimensions.width.totalInches / 12));
    },

    calculateTotalDepth() {
      if(this.dimensions.depth.feetAmount) {
        this.results.totalDepth = `${this.dimensions.depth.feetAmount} feet ${this.dimensions.depth.inchesAmount} inches`;
        return;
      }
      this.results.totalDepth = `${this.dimensions.depth.inchesAmount} inches`;
    },

    convertToInches(amount) {
      return amount * 12;
    },

    coverageAmount() {
      if(this.results.containerShape === 'circle') {
        return `... which will cover a <span class="amount-highlight">${this.results.diameter} diameter</span> <b class="amount-highlight">${this.results.containerShape} ${this.results.containerType}</b> at a depth of <span class="amount-highlight">${this.results.totalDepth}</span>!`;
      }
      return `... which will cover a total of <span class="amount-highlight">${this.results.totalSquareFootage} square feet</span> at a depth of <span class="amount-highlight">${this.results.totalDepth}</span>!`;
    },

    getSoilNeeded() {
      if(this.soilNeeded.measurementUnit === 'cubic yards') {
        return this.soilNeeded.cubicYardsAmount;
      }
      return this.soilNeeded.cubicFeetAmount;
    },

    moveCursorToEnd(e) {
      setTimeout(function(){
        e.selectionStart = e.selectionEnd = e.value.length;
        e.focus();
      }, 0)
    },

    reset() {
      this.containerType = 'bed';
      this.containerShape = 'rectangle';
      this.dimensions.length.feetAmount = 0;
      this.dimensions.length.inchesAmount = 0;
      this.dimensions.width.feetAmount = 0;
      this.dimensions.width.inchesAmount = 0;
      this.dimensions.depth.feetAmount = 0;
      this.dimensions.depth.inchesAmount = 0;
      this.dimensions.diameter.feetAmount = 0;
      this.dimensions.diameter.inchesAmount = 0;
      this.dimensions.length.totalInches = 0;
      this.dimensions.width.totalInches = 0;
      this.dimensions.depth.totalInches = 0;
      this.dimensions.diameter.radiusTotalInches = 0;
      this.results.visible = false;
    },

    setSquareWidth() {
      if(this.containerShape === 'square') {
        this.dimensions.width.feetAmount = this.dimensions.length.feetAmount;
        this.dimensions.width.inchesAmount = this.dimensions.length.inchesAmount;
      }
    },

    setContainerType(containerType) {
      this.containerType = containerType;
    },

    setContainerShape(containerShape) {
      this.containerShape = containerShape;
    },

    validateInchesAmounts(dimensions) {
      let rawDimensions = Alpine.raw(dimensions);
      for(const measurement in rawDimensions) {
        if(rawDimensions[measurement].inchesAmount > 11) {
          this.dimensions[measurement].inchesAmount = 11;
        }
      }
    }

  }));

  Alpine.data('emailSubscribe', (redirect, location, attributes, el) => ({
    init() {
      window.addEventListener('pageshow', function(event) {
        if (event.persisted) {
          this.resetForm();
        }
      });

      if(attributes) {
        let attributeList = attributes.split(',');
        attributeList.forEach(attribute => this.attributes[attribute] = true)
      }

      if(el && el.dataset.nestedModal) {
        this.inModal = el.dataset.nestedModal;
      }

      if(this.inModal && !attributes) {
        this.attributes = {};
      }
    },
    email:'',
    phone: '',
    redirect: redirect,
    location: location,
    attributes: {
      gardeningCalendar: false
    },
    submitting: false,
    errors: {
      email: false,
      emailMissing: false,
      phone: false,
      duplicatePhone: false
    },
    inModal: false,

    get isPhoneValid() {
      if(this.errors.phone && this.phone.length === 14 || this.errors.phone && this.phone.length === 0 ) {
        this.errors.phone = false;
      }
    },

    resetErrors() {
      for (const error in this.errors) {
        this.errors[error] = false;
      }
    },

    resetForm() {
      this.email = '';
      this.phone = '';
      this.submitting = false;
      for (const property in this.errors) {
        this.errors[property] = false;
      }
      this.resetErrors();
    },

    subscribe() {
      this.resetErrors();
      if(this.phone.length > 0 && this.phone.length < 14) {
        this.errors.phone = true;
        return;
      }

      let data = {
        email: this.email,
        phone: this.phone,
        location: this.location,
        attributes: this.attributes
      }

      this.submitting = true;

      const emailData = fetch('/.netlify/functions/email-subscribe', {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((response) => {
        if(response.error) {
          this.errors[response.type] = true;
          throw new Error(response.message)
        }
        if(this.inModal) {
          this.setLocalStorage()
        }
        if(el) {
          const gtm_modal_event = el.dataset.gtmModalEvent
          if (gtm_modal_event) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'event': gtm_modal_event});
          }
        }
        window.location.href = this.redirect;
      })
      .catch((error) => {
        this.submitting = false;
        console.error('Error:', error);
      });
    },
  }));

  // Roadblock modal
  Alpine.data('roadblockModal', (modalName = 'roadblockModal') => ({
    init() {
      this.openModal();
    },

    closeModal() {
      document.body.classList.remove('modal-visible', 'modal-overlay', 'offscreen-nav-visible');
      this.open = false;
      this.setLocalStorage();
    },

    setLocalStorage() {
      localStorage.setItem(this.modalName, 'shown')
    },

    openModal() {
      let modalViewed = localStorage.getItem(this.modalName);
      if(modalViewed !== 'shown') {
        this.open = true;
        document.body.classList.add('modal-visible', 'modal-overlay', 'offscreen-nav-visible');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': `${this.modalName}_view`});
      }
    },

    open: false,
    modalName: modalName
  }));

});


/* =======================
Event card read more links
========================*/
let eventCardDescriptions = document.querySelectorAll('.horizontal-event-card-description .text-container');

eventCardDescriptions.forEach(description => {
  let text = description.querySelector('.text');
  let readMore = description.querySelector('.read-more-link');

  if(text.scrollHeight < 480) {
    description.style.maxHeight = '100%';
    readMore.remove();
    return;
  }

  readMore.addEventListener('click', (e) => {
    e.target.parentElement.remove();
    description.style.maxHeight = `${text.scrollHeight}px`;
  });
});

document.addEventListener("DOMContentLoaded", function() {
  var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});